import React ,  { useState, useEffect, useContext } from "react";
import s from './CommonTask.module.scss';
import { SocketContext } from "../../../services/socket";
import { useTelegram } from "../../../hoc/useTelegram";

const CommonTask = () => {

    const socket = useContext(SocketContext);

    const [tasks, setTasks] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getListCommonTask')
            }
        },100)
  
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    useEffect(()=> {
        if (socket) {
            socket.on('listCommonTask', (data) => {
                setTasks(data)
            })
        }
      })

      useEffect(() => {
        if (socket) {
            socket.on('resCheckSubChannel',(data) => {
                if (data.sub == true) {
                    socket.emit('progressAwardSubChannel', data.task_id)
                } else {
                    window.open(data.link, '_blank', 'noopener,noreferrer');
                }
            })
        }
        return () => {
            if (socket) {
             socket.off('resCheckSubChannel');
            }
           };
      },[])


    const clickCheckSubChannel = (id_channel, task_id,link) => {
        if (socket) {
            socket.emit('checkSubChannel',id_channel,task_id,link)
        }
    };


    return(
        <div className={s.commonTask}>
            {
                tasks.map((task) => {

                    const button1 = task.status == 'subscribe' ? <div className={s.executeEnd}>Выполнено</div>
                    :<div className={s.execute} onClick={() => clickCheckSubChannel(task.id_channel,task.id,task.link)}>Выполнить</div>
                   // const button2 = <div className={s.execute} onClick={() => openWebLink(task.link,task.id_channel, task.id)}>Выполнить</div>

                    return (
                        <div className={s.task}>
                                        <div className={s.desc}>
                                            <div className={s.name}>
                                                {task.name_task} 
                                            </div>
                                            <div className={s.award}>
                                                {task.desc_task}
                                            </div>
                                        </div>
                                        {button1}
                                    </div>
                    )
                })
            }
             
        </div>
    )
}

export default CommonTask;