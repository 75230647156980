import React from "react";
import s from './Footer.module.scss';

import { NavLink } from 'react-router-dom';

const setActive = ({isActive}) => isActive ? s.navlinkActive : s.navlink;

const Footer = () => {
    return(
        <div className={s.footer}>
          <div className={s.items}> 
                <NavLink to="/" className={setActive}>
                    <div className={s.home}></div>
                </NavLink>
            </div>
         
            <div className={s.items}> 
                <NavLink to="/friends" className={setActive}>
                <div className={s.friends}></div>
                </NavLink>
            </div>
            <div className={s.items}> 
                <NavLink to="/tasks" className={setActive}>
                    <div className={s.task}></div>
                </NavLink>
            </div>
      
          
        </div>
    )
}

export default Footer;

/*
  <div className={s.items}> 
                <NavLink to="/news" className={setActive}>
                <div className={s.news}></div>
                </NavLink>
            </div>


               <div className={s.items}> 
                <NavLink to="/drop" className={setActive}>
                <div className={s.drop}></div>
                </NavLink>
            </div>
            <div className={s.items}> 
                <NavLink to="/safe" className={setActive}>
                <div className={s.safe}></div>
                </NavLink>
            </div>

                  <div className={s.items}> 
                <NavLink to="/address" className={setActive}>
                <div className={s.address}></div>
                </NavLink>
            </div>
            */