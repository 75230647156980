import React, {useEffect, useState, useContext, useRef} from 'react';
import Content from '../components/Home/Content/Content';
import Header from '../components/Home/Header/Header';
import Footer from '../components/Footer/Footer';
import s from './App.module.scss';
import { Routes, Route } from 'react-router-dom';
import News from '../components/News/News';
import Tasks from '../components/Tasks/Tasks';
import Home from '../components/Home/Home';
import Friends from '../components/Friends/Friends';
import Address from '../components/Address/Address';
import { useDispatch} from 'react-redux';
import { SocketContext } from '../services/socket';
import { getUserInfo } from '../store/reducers/counterReducers';
import {jwtDecode} from "jwt-decode";
import mp3file from './../assets/sound/white.mp3'

const App = () => {

    const socket = useContext(SocketContext);
    const dispatch = useDispatch(); 

    useEffect(() => {
        if (socket) {
          socket.on('connected', (data) => {
            const decoded = jwtDecode(data,"jfdgdfgdf")
         
           const payload = {
                counter: decoded.data.game_score,
                value: 0,
                uuid: decoded.data.username,
                username: decoded.data.username,
                first_name: decoded.data.first_name,
                streak: decoded.data.streak,
                free_poke: decoded.data.free_poke,
                next_poke: decoded.data.next_poke,
                common: decoded.data.common,
                uncommon: decoded.data.uncommon,
                rare: decoded.data.rare,
                epic: decoded.data.epic,
                legendary: decoded.data.legendary,
                last_streak_received: decoded.data.last_streak_received,
                last_ref_received: decoded.data.last_ref_received
            }
            dispatch(getUserInfo(payload))
            
        })
        }
    })
    let audio = new Audio(mp3file)
    const startAudio = () => {
        audio.play()
      }

    return(
          <div className={s.app}>
                <Routes >
                <Route path="/address" element={<Address />} />
                      <Route path="/" index element={<Home />} />
                        <Route path="/tasks" element={<Tasks />} />
                        <Route path="/friends" element={<Friends />}/>
                </Routes>
                <Footer />
            </div>
    )
}

export default App;

//<Route path="/news" element={<News />} />